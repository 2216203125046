<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.visitDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="dateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.memberDivs }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                    v-model="searchConditions.memberDivs"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.memberDivs"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="180"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.paymtCodes }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                    v-model="searchConditions.paymtCodes"
                    cssClass="lookup-condition-multiselect"
                    :dataSource="searchOptions.paymtCodes"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="180"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.visitName }}
            </div>
            <ul class="content">
              <li class="item" style="width: 80px">
                <input-text
                  v-model="searchConditions.visitName"
                  @keypress.enter.native="onViewPaymentVisitStatements"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.grpName}}
            </div>
            <ul class="content">
              <li class="item" style="width: 80px">
                <input-text
                  v-model="searchConditions.grpName"
                  @keypress.enter.native="onViewPaymentVisitStatements"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="searchConditions.isAutoCheckedIn"
                          value="false"
                      />
                      <i/>
                      <div class="label">모바일체크인</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="checkbox"
                          v-model="searchConditions.isNot"
                          value="false"
                      />
                      <i/>
                      <div class="label">미적용 제외</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewPaymentVisitStatements"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">요금구분별 내장현황</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="print"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      :ignore="isPopupOpened"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="paymentVisitStatementsGrid"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowGrouping="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :groupSettings="groupSettings"
                  :columns="paymentVisitStatementsGridColumn"
                  :dataSource="paymentVisitStatementsList"
                  :aggregates="paymentVisitStatementsGridAggregates"
                  @queryCellInfo="paymentVisitStatementsGridQueryCellInfo"
                  @actionComplete="paymentVisitStatementsGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import moment from 'moment';
import {mapGetters} from 'vuex';
import {DATE_FORMAT_YYYY_MM_DD, getDayOfWeekCaptionColor} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName} from '@/utils/commonCodes';
import GolfERPService from '@/service/GolfERPService';
import {gridUtilGetMemberNoColumnAccess} from '@/utils/gridUtil';
import InputText from "@/components/common/text/InputText";
import ReportView from "@/components/common/report/ReportView";

export default {
  name: 'paymtCodeByVisitStatements',
  components: {
    ejsGridWrapper,
    InputDateRange,
    InputText,
    ReportView,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      labels: {
        visitDate: '내장일자',
        memberDivs: '회원구분',
        paymtCodes: '요금구분',
        isNot: '미적용 제외',
        visitName: "내장객명",
        grpName: "단체명",
      },
      searchConditions: {
        memberDivs: [],
        paymtCodes: [],
        isAutoCheckedIn: false,
        isNot: true,
        visitName: null,
        grpName: null,
      },
      searchOptions: {
        memberDivs: commonCodesGetCommonCode('MEMBER_DIV', true),
        paymtCodes: commonCodesGetCommonCode('PAYMT_CODE', true),
      },
      commonCodeFields: {text: 'comName', value: 'comCode'},
      now: null,
      dateRange: {
        from: null,
        to: null,
      },
      grid: [
        Aggregate,
        Filter,
        Resize,
        Page,
        Group,
        ExcelExport,
        ForeignKey,
      ],
      paymentVisitStatementsGridColumn: [
        {
          field: 'chkinId',
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'visitDateKey',
          type: 'string',
          visible: false,
        },
        {
          field: 'visitDate',
          headerText: '내장일자',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: "dwCode",
          headerText: "요일",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "DW_CODE",
          width: 70,
          type: "string",
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "BSN_CODE",
          width: 90,
          type: "string",
        },
        {
          field: 'startTime',
          headerText: '시간',
          textAlign: 'center',
          width: 70,
          type: 'string',
        },
        {
          field: 'startCourse',
          headerText: '코스',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 90,
          type: 'string',
        },
        {
          field: 'resveName',
          headerText: '예약자',
          width: 90,
          type: 'string',
        },
        {
          field: "resveMemberNo",
          headerText: '회원번호',
          textAlign: "center",
          width: 90,
          type: 'string',
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: 'grpName',
          headerText: '단체명',
          width: 120,
          type: 'string',
        },
        {
          field: 'visitName',
          headerText: '내장자',
          width: 90,
          type: 'string',
        },
        {
          field: 'sexCode',
          headerText: '성별',
          width: 60,
          type: 'string',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'SEX_CODE',
        },
        {
          field: 'memberNo',
          headerText: '회원번호',
          textAlign: 'center',
          width: 90,
          type: 'string',
          valueAccessor: gridUtilGetMemberNoColumnAccess,
        },
        {
          field: 'autoChkinFlag',
          headerText: '모바일체크인',
          width: 110,
          textAlign: 'center',
          type: 'boolean',
          displayAsCheckBox: 'true',
        },
        {
          field: 'lockerNo',
          headerText: '락카',
          textAlign: 'center',
          width: 70,
          type: 'string',
        },
        {
          field: 'memberDiv',
          headerText: '회원구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'MEMBER_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'partDiv',
          headerText: '부구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'PART_DIV',
          width: 60,
          type: 'string',
        },
        {
          field: 'teamHoleDiv',
          headerText: '홀구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'HOLE_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'paymtCode',
          headerText: '요금구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'PAYMT_CODE',
          width: 90,
          type: 'string',
        },
        {
          field: 'totAmt',
          headerText: '요금',
          textAlign: 'right',
          isNumericType: true,
          width: 90,
          type: 'number',
        },
        {
          field: 'dcContents',
          headerText: '할인내용',
          width: 150,
          type: 'string',
        },
      ],
      paymentVisitStatementsGridAggregates: [
        {
          columns: [
            {
              field: 'teamHoleDiv',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: '합계',
            },
            {
              field: 'paymtCode',
              aggregationType: 'TotalCaption',
              textAlign: 'right',
              customAggregate: () => {
                const gridData = this.$refs.paymentVisitStatementsGrid.getGridBatchData();
                const len = gridData.length;
                const lengthLabel = len > 0
                  ? `${len} 건`
                  : '-';
                const autoChkinFlag = gridData
                    .map(item => item.autoChkinFlag)
                    .filter(item => !!item)
                    .reduce((acc, cur) => cur + acc, 0);
                const autoChkinFlagLabel = `${autoChkinFlag} 건`;
                return autoChkinFlag > 0
                  ? `${lengthLabel} (모바일: ${autoChkinFlagLabel})`
                  : lengthLabel;
              },
            },
            {
              field: 'totAmt',
              aggregationType: 'TotalSum',
            },
          ],
        },
      ],
      paymentVisitStatementsList: [],
      sanctnLineList: [],
      pageSettings: {pageSize: 50},
      groupSettings: {
        columns: ['visitDateKey'],
        showDropArea: false,
      },
    };
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false; // TODO : 팝업 있을 경우, 삽입
    },
  },
  methods: {
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    numberWithCommas,
    async initialize() {
      const nowMoment = await GolfERPService.fetchNow();
      this.now = nowMoment.toDate();
      const from = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      this.dateRange = {
        from,
        to,
      };
      await this.fetch();
    },
    async onViewPaymentVisitStatements() {
      await this.fetch();
    },
    paymentVisitStatementsGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;
      if ([
        'visitName',
        'paymtCode',
      ].includes(field)) {
        cell.style.color = commonCodesGetColorValue(
            'PAYMT_CODE',
            args.data.paymtCode,
        );
      }
      if (field === "teamHoleDiv") {
        args.cell.style.backgroundColor = commonCodesGetColorValue(
            "HOLE_DIV",
            data.teamHoleDiv
        );
      }
      if (field === 'totAmt' && data[field] < 1) {
        cell.innerText = '-';
      }
      if (data.teamDivLine) {
        cell.style.borderTop = `1px solid #999`;
      }
    },
    paymentVisitStatementsGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.paymentVisitStatementsGrid?.getGridBatchCount() || 0,
      );
    },
    excel() {
      this.$refs.paymentVisitStatementsGrid.excelExport();
    },

    // API
    async fetch() {
      let chkinId = null;
      const data = await GolfErpAPI.fetchPaymtCodeByVisitStatements({
        visitDateFrom: this.dateRange.from,
        visitDateTo: this.dateRange.to,
        memberDivs: this.searchConditions.memberDivs,
        paymtCodes: this.searchConditions.paymtCodes,
        isAutoCheckedIn: this.searchConditions.isAutoCheckedIn,
        isNot: this.searchConditions.isNot,
        visitName: this.searchConditions.visitName,
        gcDiv: "G",
        grpName: this.searchConditions.grpName,
      });

      this.paymentVisitStatementsList = data.paymtCodeByVisitStatements.map(item => {
        item.teamDivLine = chkinId && chkinId !== item.chkinId;
        item.visitDateKey = item.visitDate;
        item.visitDate = moment(item.visitDate).format(DATE_FORMAT_YYYY_MM_DD);
        item.startTime = item.startTime.substring(0, 2) + ':' + item.startTime.substring(2);
        chkinId = item.chkinId;
        return item;
      }) || [];

      this.sanctnLineList = data.sanctnLineList || [];
    },
    print() {
      const options = [
        {
          field: "startCourse",
          comCode: "COURSE_CODE",
          format: "comCode",
        },
        {
          field: "memberDiv",
          comCode: "MEMBER_DIV",
          format: "comCode",
        },
        {
          field: "teamHoleDiv",
          comCode: "HOLE_DIV",
          format: "comCode",
        },
        {
          field: "paymtCode",
          comCode: "PAYMT_CODE",
          format: "comCode",
        },
        {
          field: "dwCode",
          comCode: "DW_CODE",
          format: "comCode",
        },
        {
          field: "bsnCode",
          comCode: "BSN_CODE",
          format: "comCode",
        },
        {
          field: "resveMemberNo",
          format: "memberNo",
        },
        {
          field: "memberNo",
          format: "memberNo",
        },
        {
          field: "autoChkinFlag",
          format: "boolean",
        }
      ];

      const result = this.$refs.paymentVisitStatementsGrid.getGridBatchData(options).map((data) => ({
        ...data,
        sexCode: commonCodesGetComName("SEX_CODE",data.sexCode)
      }));

      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const searchOptionsList = [
        {
          key: "내장일자",
          value: this.dateRange.from + " ~ " + this.dateRange.to,
        },
        {
          key: "회원구분",
          value:
            this.searchConditions.memberDivs.length > 0 ?
              this.searchConditions.memberDivs.map(item => commonCodesGetComName("MEMBER_DIV", item)).join(", ") :
              "전체",
        },
        {
          key: "요금구분",
          value:
            this.searchConditions.paymtCodes.length > 0 ?
              this.searchConditions.paymtCodes.map(item => commonCodesGetComName("PAYMT_CODE", item)).join(", ") :
              "전체",
        },
        {
          key: "내장객명",
          value: this.searchConditions.visitName,
        },
        {
          key: "모바일체크인",
          value: !this.searchConditions.isAutoCheckedIn ? null : "예",
        },
        {
          key: "미적용제외",
          value: this.searchConditions.isNot ? null : "예",
        }
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: {
            paymtCodeByVisitStatements: result,
            sanctnLineList: this.sanctnLineList,
          },
          userName: this.username,
          uniqueCode: this.$options.name,
          searchOptions
        }
      };

      this.$refs.reportViewComponent.postReport(reportData, "PaymtCodeByVisitStatements");
    }
  },
};
</script>
