<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">실사년월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "stockYearMonth"
                    :format="DATE_FORMAT_YYYY_MM"
                    :notClear="true"
                    depth="Year"
                    v-model="searchFilter.stockYm"
                    type="lookup-condition"
                    @change="onYearMonthChange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article detail-view-opened" >
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField.title }}</div>
                <div class="header-caption">[{{ gridField.count }} 건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind="initGrid"
                    @actionComplete="gridComplete"
                    @recordClick="onGridClick"
                />
              </div>
            </div>
          </section>
        </div>
        <div class="article-right">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField2.title }}</div>
                <div class="header-caption">{{getCurrentMonth}}</div>
                <div class="header-caption">[{{ gridField2.count }} 건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" v-if="isSaveButton" :is-shortcut-button="false" @click.native="onClickSave">실사확정</erp-button>
                  </li>
                  <li class="delete keyColor">
                    <erp-button button-div="DELETE" v-if="isGeDelButton" :is-shortcut-button="false" @click.native="onClickDelete">실사취소</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid table-side">
                <ejs-grid-wrapper
                    ref="grid2"
                    v-bind="initGrid2"
                    @headerCellInfo="onHeaderCellInfo2"
                    @queryCellInfo="onQueryCellInfo2"
                    @actionComplete="gridComplete2"/>
              </div>
            </div>
          </section>
        </div>
      </article>

    </div>
    <!-- 각종팝업 -->
  </div>
</template>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<script>


import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {
  DATE_FORMAT_YYYY_MM,
  getFormattedDate,
} from "@/utils/date";
import {mapGetters} from "vuex";
import { validateFormRefs} from "@/utils/formUtil";
import InputDate from "@/components/common/datetime/InputDate.vue";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import StockProductStat from "@/api/v2/StockManage/StockProductStat";

export default {
  name: "ProductStockMonthlyWrhous",
  mixins: [commonMixin, messagePopupDialogMixin],
  components: { InputDate,  ejsGridWrapper, ErpButton, },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      searchFilter: {
        stockYm: null,
      },

      //드롭다운 코드
      commonCodeOptions: {
        wrhousData:[],
        fields: { text: "comName", value: "comCode" },
      },
      //detail
      detailData: {
        stockYm: null,
        wrhousCode: null,
        stockId: null,
        dtlListD:[],
        dtlListU:[],
        dtlListI:[],
      },
      gridField: {
        title: "창고 목록",
        count: 0,
        dataSource: [],
      },
      gridField2: {
        title: "실사 상세",
        count: 0,
        dataSource: [],
        gridSelectWrhous:null,
      },
      //버튼 상태
      isSaveButton : false,
      isGeDelButton : false,
    };
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    isPopupOpened() {
      return false;
    },
    //실사월 포멧
    getCurrentMonth(){
      return  getFormattedDate(this.searchFilter.stockYm, DATE_FORMAT_YYYY_MM);
    },

    initGrid() {
      return {
        columns: [
          {field: 'wrhousCode',    textAlign: 'center', width: 80,   visible: true,    allowEditing : false,  type: 'string',   headerText: '창고', isPrimaryKey: true, isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData},
          {field: 'insertFlag',    textAlign: 'center', width: 60,   visible: true,    allowEditing : false,  type: 'boolean',  headerText: '실사여부', displayAsCheckBox: true,},
          {field: 'remarks',       textAlign: 'left',   width: 150,  visible: false,   allowEditing : false,  type: 'string',   headerText: '비고'},
          {field: 'insertName',    textAlign: 'center', width: 80,   visible: true,    allowEditing : false,  type: 'string',   headerText: '실사자', },
          {field: 'insertDt',      textAlign: 'center', width: 100,  visible: true,    allowEditing : false,  type: 'string',   headerText: '등록일시', },
          {field: 'stockId',       textAlign: 'center', width: 80,   visible: false,   allowEditing : false,  type: 'string',   headerText: '재고ID', },
          {field: 'stockYm',       textAlign: 'center', width: 80,   visible: false,   allowEditing : false,  type: 'string',   headerText: '실사월', valueAccessor: (field,data) => getFormattedDate(data[field],DATE_FORMAT_YYYY_MM) },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
      };
    },
    initGrid2() {
      return {
        columns: [
          { field: "dtlId",           textAlign: 'center', width: 70,   visible: false, allowEditing: false, type: "string",  allowSorting:false, headerText: "상세ID", },
          { field: "pathDesc",        textAlign: 'left',   width: 150,  visible: true,  allowEditing: false, type: "string",  allowSorting:false, headerText: "분류",},
          { field: "productCode",     textAlign: 'center', width: 140,  visible: true,  allowEditing: false, type: "string",  allowSorting:false, headerText: "상품코드",},
          { field: "productName",     textAlign: 'left',   width: 150,  visible: true,  allowEditing: false, type: "string",  allowSorting:false, headerText: "상품", },
          { field: "standard",        textAlign: 'center', width: 90,   visible: true,  allowEditing: false, type: "string",  allowSorting:false, headerText: "규격", },
          { field: "unit",            textAlign: 'center', width: 90,   visible: true,  allowEditing: false, type: "string",  allowSorting:false, headerText: "단위", },
          { field: "lsmthStock",      textAlign: 'right',  width: 50,   visible: true,  allowEditing: false, type: "number",  allowSorting:false, headerText: "전월재고", isNumericType: true, },
          { field: "stockQty",        textAlign: 'right',  width: 50,   visible: true,  allowEditing: true,  type: "number",  allowSorting:false, headerText: "실사수량",
            isNumericType: true, inputNumberProperty: {allowDot: true,maxUnderDotLength: 3, allowMinus: false}},
          {field: 'remarks',          textAlign: 'left',   width: 150,  visible: true,   allowEditing: true,  type: 'string',  allowSorting:false, headerText: '비고'},
          {field: 'insertName',       textAlign: 'center', width: 80,   visible: false,  allowEditing: false, type: 'string',  allowSorting:false, headerText: '실사자', },
          {field: 'insertDt',         textAlign: 'center', width: 100,  visible: false,  allowEditing: false, type: 'string',  allowSorting:false, headerText: '등록일시', },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        //그리드 정합성 체크
        validationRules: {
          stockQty : {required: true}
        },
      };

    },
  },

  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //폼정합성 체크
    validateFormRefs,
    //기초데이터 세팅
    async initData() {
      this.searchFilter.stockYm = moment().format(DATE_FORMAT_YYYY_MM);  //실사년월 기본 값
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'N',authoFlag:'Y',suppliesFlag:'N'});  //상품 창고
    },
    //데이터 초기화
    clearDetailData() {
      this.detailData = {
        stockYm: null,
        wrhousCode: null,
        stockId: null,
        dtlListD: [],
        dtlListU: [],
        dtlListI: [],
      };
      this.gridField2.dataSource = [];
      this.isSaveButton = false;
      this.isGeDelButton = false;
    },

    //******************************* 버튼 및 컨트롤 이벤트 *******************************
    //실사년월 변경시
    onYearMonthChange() {
      this.onClickSearch();
    },
    //조회
    async onClickSearch(){
      //해당월 재고 실사 내역 조회
      this.gridField.dataSource = await StockProductStat.getProductStockMonthlyWrhousList(this.searchFilter);
      //기 선택된 행이 있다면 찾아서 자동 선택
      setTimeout(() => {
        if(this.gridField.dataSource?.length > 0){
          const idx = this.$refs.grid.getRowIndexByPrimaryKey(this.gridField2.gridSelectWrhous?.wrhousCode);
          const rowIdx = idx > -1 ? idx : 0 ;
          this.$refs.grid.selectRow(rowIdx);
          this.$refs.grid.getRowByIndex(rowIdx).click();
        }
      }, 100);
    },

    //상세조회 API
    async getDetailItemApi(rowData){
      this.clearDetailData();
      //HDR키 세팅
      this.detailData.stockYm     = rowData.stockYm;
      this.detailData.stockId     = rowData.stockId;
      this.detailData.wrhousCode  = rowData.wrhousCode;
      this.detailData.insertFlag  = rowData.insertFlag;
      //선택된 행
      this.gridField2.gridSelectWrhous = rowData;

      //실사자료 조회
      const dataSource  = await StockProductStat.getProductStockMonthlyWrhousDetail(rowData);
      //그리드 세팅(신규면 신규행으로)
      if(rowData.insertFlag == true){
        this.gridField2.dataSource = dataSource;
      }else{
        if(dataSource !== null){
          for (const data of dataSource) {
            this.$refs.grid2.addRecord(data);
          }
        }
        //ROW- COUNT(추가행만, 일반 조회는 gridComplete2에서 수행
        this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
      }
      //마감확정 활성화
      this.isSaveButton =  !rowData.insertFlag ;
      //마감삭제 홝성화
      this.isGeDelButton = rowData.insertFlag;

      //실사항목 활성(신규만), 비활성화
      /*setTimeout(() => {
        this.$refs.grid2.getColumnObject("stockQty").allowEditing = args.insertFlag == false;
      }, 50);*/

      /*if(this.gridField2.count > 0) {
        this.$refs.grid2.editCell(0, "stockQty");
      }*/
    },

    //저장(실사등록)
    async onClickSave() {
      //1. 그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }

      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.detailData.dtlListD = deletedRecords;
      this.detailData.dtlListU = changedRecords;
      this.detailData.dtlListI = addedRecords;

      if(addedRecords.length === 0) {
        //this.errorToast(`저장할 실사 상품이 없습니다.`);
        if (!(await this.confirm(`저장할 실사 상품이 없습니다.<br>해당창고의 [${this.getCurrentMonth}] 실사를 확정하시겠습니까?`))) {
          return;
        }
      }

      //2.저장
      //await this.apiSave();
      await StockProductStat.postProductStockMonthlyWrhousDetail(this.detailData);
      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      await this.onClickSearch();
    },

    //삭제(실사해제)
    async onClickDelete(){
      //1. 확인
      if (!(await this.confirm(`해당창고의 [${this.getCurrentMonth}] 실사를 취소하시겠습니까?`))) {
        return;
      }
      //2. 삭제처리
      const args = {stockYm:this.detailData.stockYm, deleteKey:this.detailData.stockId};
      await StockProductStat.deleteProductStockMonthlyWrhousDetail(args);
      //3. 메세지 호출
      this.infoToast(`실사 삭제 되었습니다.`);
      await this.onClickSearch();
    },

    //엑셀
    onClickExcel(){
      if(this.gridField2.dataSource.length > 0 ){
        this.$refs.grid2.excelExport({fileName: this.gridField2.title+".xlsx"});
      }else{
        const gridData = this.$refs.grid2.getBatchCurrentViewRecords();
        this.$refs.grid2.excelExport({dataSource:gridData,fileName: this.gridField2.title+".xlsx"});
      }
    },

    // ******************************* 그리드 이벤트 *******************************
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onQueryCellInfo2(args) {
      const {column:{field,allowEditing}, cell,} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onGridClick(args){
      const {rowData} = args;
      //실사자료 조회
      this.getDetailItemApi(rowData);
    },
  },

};

</script>
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
/*body .appContent .body-article .article-right{margin-right: calc(-70% - 0px)}*/
body .appContent .body-article.detail-view-opened .article-left{width:calc(30% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(70% - 0px); margin:0}
body .body-footer .section-header {
  display: block;
  position: relative;
  margin: 0px 0px;
}
.voc-section {
  width: 100% !important;
}

</style>