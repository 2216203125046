<template>
  <div class="appHeader">
    <div class="header-left">
      <div class="header-sideLeft">
        <erp-button
          button-div="CLOSE"
          :is-custom-shortcut-button="true"
          :shortcut="toggleSidebarButtonProps.shortcut"
          :shortcut-key="toggleSidebarButtonProps.shortcutKey"
          @click.native="toggleSideBar"
        >
          좌측 사이드
        </erp-button>
        <div class="item">
          {{ toShortcutString(toggleSidebarButtonProps.shortcut) }}
        </div>
      </div>
      <menu-user-mark />
      <ul class="header-breadcrumb">
        <li
          v-for="navigationItem in navigationList"
          :key="navigationItem.id"
        >
          {{ navigationItem.name }}
        </li>
        <li class="home">
          <erp-button button-div="CLOSE" @click.native="goToHome">
            HOME
          </erp-button>
        </li>
      </ul>
    </div>
    <div class="header-right">
      <div class="header-business">
        <ejs-dropdownlist
          ref="bizCodeItemsDropdownList"
          cssClass="header-business-dropdown"
          placeholder="사업장을 선택하세요"
          width="163px"
          popupWidth=""
          v-model="currentBizCode"
          :dataSource="bizCodeItems"
          :fields="bizCodeFields"
          :select="onBizCodeSelect"
          :allowFiltering="false"
        >
          사업장
        </ejs-dropdownlist>
      </div>
      <div class="header-day">
        {{ todayDate }}
      </div>
      <ul class="header-button">
        <li class="blank">
          <erp-button button-div="CLOSE" title="새 창" @click.native="onNewScreen">
            새 창
          </erp-button>
        </li>
        <li class="full">
          <erp-button button-div="CLOSE" title="전체화면" @click.native="onFullScreen">
            전체화면
          </erp-button>
        </li>
        <li class="sos">
          <erp-button button-div="CLOSE" title="원격지원" @click.native="onRemoteSupport">
            원격지원
          </erp-button>
        </li>
        <li :class="['hold', isHoldERP && 'active']">
          <ejs-dropdownbutton
              :title="holdErpButtonLabel"
              :disabled="!deviceSettingInfo.isClientMonitorUse"
              cssClass="header-button-user-dropdown"
              :items="clientMonitorItems"
              :select="onHoldERP"
              v-model="clientMonitorValue"
          >
            {{ holdErpButtonLabel }}
          </ejs-dropdownbutton>
        </li>
        <li class="refresh">
          <ejs-dropdownbutton
            title="새로고침"
            cssClass="header-button-user-dropdown"
            :items="refreshMenuItems"
            :select="onRefreshMenuSelect"
          />
        </li>
        <li class="user">
          <ejs-dropdownbutton
            ref="userMenuDropdownButton"
            cssClass="header-button-user-dropdown"
            :items="userMenuItems"
            :select="onUserMenuSelect"
          >
            {{ username }}
          </ejs-dropdownbutton>
        </li>
      </ul>
      <!-- 신규 활성 : activeNew -->
      <div :class="[
        'header-sideRight',
        is_new_message ? 'activeNew' : '',
      ]">
        <erp-button button-div="CLOSE" @click.native="onRightTabIconClicked">
          우측 사이드
        </erp-button>
        <div class="new">
          N
        </div>
      </div>
    </div>
    <ejs-dialog
      v-if="isPasswordChangeDialogPopupOpened"
      id="passwordChangeDialog"
      ref="passwordChangeDialog"
      :header="passwordChangeDialogHeader"
      :width="285"
      :isModal="true"
      :showCloseIcon="true"
      :closeOnEscape="true"
      :visible="false"
      :animationSettings="{ effect: 'None' }"
      @close="onPasswordChangeDialogClose"
    >
      <div class="window common-changePassword">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-caption">
                      본 시스템에서는 비밀번호를<br/>
                      최소 1개월마다 변경하기를 권하고 있습니다.<br/><br/>
                      <div v-if="systemConfigInfo.pwdScrtyLevel === 'L'">
                        ㆍ 8~15자의 영문, 숫자, 특수문자만 가능.<br/>
                        ㆍ 영문, 숫자 혼용.<br/>
                        ㆍ 공백문자 사용불가.<br/>
                        ㆍ 일부 특수문자 사용가능. (! @@ # $ % ^ & * ( ) _ + |
                        { } &lt; &gt; : ; [ ] - = , . ?)
                      </div>
                      <div v-else>
                        ㆍ 쉬움 설정의 제약 포함.<br/>
                        ㆍ 본인 Email ID 포함 불가.<br/>
                        ㆍ 3자리 이상 반복문자 사용불가.<br/>
                        ㆍ 3회 이상 연속 된 알파벳 사용 불가.<br/>
                        ㆍ 3회 이상 연속 된 숫자 사용 불가.
                      </div>
                    </div>
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">이전 비밀번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input
                                        id="input-old-password"
                                        ref="input-old-password"
                                        class="e-input"
                                        type="password"
                                        autocomplete="off"
                                        v-model="oldPassword"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">신규 비밀번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input
                                        id="input-new-password"
                                        ref="input-new-password"
                                        class="e-input"
                                        type="password"
                                        autocomplete="off"
                                        v-model="newPassword"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">비밀번호 확인</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input
                                        id="input-repeat-password"
                                        ref="input-repeat-password"
                                        class="e-input"
                                        type="password"
                                        autocomplete="off"
                                        v-model="repeatPassword"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button button-div="CLOSE" :is-ignore-disabled="true" @click.native="savePassword">
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="cancelPassword">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <device-setting-popup
      v-if="isDeviceSettingPopupOpened"
      @popupClosed="onDeviceSettingPopupClosed"
    />
  </div>
</template>

<style scoped>
.header-sideLeft {position: relative;}
.header-sideLeft .item {display: none;position: absolute;top: -11px;left: 16px;padding: 2px 3px 0 2px;border-radius: 3px;background-color: #222;font-size: 11px;line-height: 16px;color: #fff;}
.header-sideLeft .e-btn:hover + .item {display: block;}
</style>

<script>
import {sortBy as _sortBy} from 'lodash';
import {mapGetters, mapState, mapMutations} from 'vuex';
import {openNewWindow} from '@/utils/appInfo';
import {getDeviceInfo} from '@/utils/device';
import {getTodayNavigationDate} from '@/utils/date';
import {validateFormRefs} from '@/utils/formUtil';
import {
  getGlobalMenuToggleButtonShortcut,
  toShortcutString,
} from '@/utils/KeyboardUtil';
import {
  getBizCodesObject,
  getCurrentBizCode,
  setCurrentBizCode,
} from '@/utils/pageKeyUtil';
import MenuUserMark from '@/components/common/MenuUserMark';
import DeviceSettingPopup from './deviceSettingPopup';
import commonMixin from '@/views/layout/mixin/commonMixin';
import messagePopupDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {getSystemConfigInfo} from '@/api/systemConfig';
import GolfERPService from "@/service/GolfERPService";
import ErpButton from "@/components/button/ErpButton";

const documentBaseTitle = 'GreenIT ERP';

export default {
  name: 'appHeader',
  components: {
    DeviceSettingPopup,
    MenuUserMark,
    ErpButton,
  },
  mixins: [commonMixin, messagePopupDialogMixin],
  data() {
    return {
      deviceSettingInfo: getDeviceInfo(),
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      changePasswordValidationRules: {
        'input-old-password': {
          required: true,
        },
        'input-new-password': {
          required: true,
        },
        'input-repeat-password': {
          required: true,
          custom: {
            method: (value) => value === this.newPassword,
            message: '비밀번호 확인이 맞지 않습니다. 비밀번호를 다시 확인해주세요.',
          },
        },
      },
      clientMonitorTitle: "OFF",
      clientMonitorValue : getDeviceInfo().clientMonitorImagePattern,
      passwordChangeDialogHeader: this.$t('main.title.changePassword'),
      navigationList: [],
      todayDate: null,
      bizCodeFields: {text: 'text', value: 'value'},
      currentBizCode: '',
      systemConfigInfo: {},
      isPasswordChangeDialogPopupOpened: false,
      isDeviceSettingPopupOpened: false,
      timeInterval: 60,
    };
  },
  watch: {
    $route() {
      this.setNavigationList();
    },
  },
  computed: {
    ...mapGetters(['username', 'menuByRouterName', 'menuByParentId', 'systemVersionId', 'isHoldERP']),
    ...mapState('fcm', ['is_new_message']),
    bizCodeItems() {
      return this.bizCodes?.map(({ bizName, bizCode }) => ({
        text: bizName,
        value: bizCode,
      })) || [];
    },
    isPopupOpened() {
      return this.isDeviceSettingPopupOpened;
    },
    toggleSidebarButtonProps() {
      return {
        shortcutKey: 'AppHeader.shortcuts.toggleSidebar',
        shortcut: getGlobalMenuToggleButtonShortcut(),
      };
    },
    refreshMenuItems() {
      return [
        {
          text: this.$t('main.title.softRefresh'),
        },
        {
          text: this.$t('main.title.hardRefresh'),
        },
      ];
    },
    clientMonitorItems() {
      return [
        {
          text: "OFF",
          value: "OFF",
          disabled: !this.isHoldERP
        },
        {
          text: this.$t('main.title.hold1'),
          value: 'HOLD_TYPE'
        },
        {
          text: this.$t('main.title.hold2'),
          value: 'HOLD_TYPE2'
        },
        {
          text: this.$t('main.title.hold3'),
          value: 'HOLD_TYPE3'
        }
      ];
    },
    userMenuItems() {
      return [
        /*
        {
          text: `<strong>${this.username}</strong>님 환영합니다.`
        },
        {
          separator: true
        },
        */
        {
          text: this.$t('main.title.deviceSetting'),
        },
        {
          text: this.$t('main.title.changePassword'),
        },
        {
          text: this.$t('main.title.logout'),
        },
      ];
    },
    bizCodes() {
      return getBizCodesObject();
    },
    holdErpButtonLabel() {
      return this.deviceSettingInfo.isClientMonitorUse
        ? `${this.clientMonitorTitle}`
        : '고객 모니터 ON 필요';
    },
  },
  async created() {
    this.setNavigationList();
    // 현재 사업장 값 설정
    this.currentBizCode = getCurrentBizCode();
  },
  async mounted() {
    await this.getToday();
    await this.fetchSystemConfigInfo();
  },
  methods: {
    ...mapMutations(['SET_LOGIN_VIEW_UNIQUE_ID', 'SET_IS_HOLD_ERP']),
    toShortcutString,
    validateFormRefs,
    async getToday() {
      this.todayDate = await getTodayNavigationDate();
    },
    async goToHome() {
      await this.$router.push({ name: this.$t('main.mainRouteName') });
    },
    onBizCodeSelect(args) {
      const selecteBizCodes = this.bizCodeItems.find(({value}) => args.itemData.value === value);
      if (selecteBizCodes) {
        setCurrentBizCode(selecteBizCodes.bizCode);
        alert(selecteBizCodes.bizName + ' 정보를 출력합니다');
        window.location.reload();
      } else {
        alert('사업장 정보가 잘못되었습니다. 다시 확인해 주시기 바랍니다');
        args.cancel = true;
      }
    },
    async onUserMenuSelect(args) {
      if (args.item.text === this.$t('main.title.changePassword')) {
        this.isPasswordChangeDialogPopupOpened = true;
        await this.$nextTick();
        this.$refs.passwordChangeDialog.show();
        // this.onPasswordChangeOpen() // TODO : 관련 메소드들 확인 후 삭제
      } else if (args.item.text === this.$t('main.title.logout')) {
        this.confirm(this.$t('main.popupMessage.logout')).then((result) => {
          if (result) {
            GolfERPService.logout({
              isSessionExpired: false,
            });
          }
        });
        // this.$refs.confirmPopup.openPopup(this.$t('main.title.confirm'), this.$t('main.popupMessage.logout'), this.confirmPopupId.logoutConfirmId)
      } else if (args.item.text === this.$t('main.title.deviceSetting')) {
        this.deviceSettingPopupOpen();
      }
    },
    onRefreshMenuSelect(args) {
      if (args.item.text === this.$t('main.title.softRefresh')) {
        window.location.reload();
      } else if (args.item.text === this.$t('main.title.hardRefresh')) {
        window.location.reload(true);
      }
    },
    onNewScreen() {
      let routeData = this.$router.resolve({name: this.$route.name});
      openNewWindow(routeData);
    },
    onFullScreen() {
      this.toggleFullScreen();
    },
    async onUpdateERP() {
      await this.alert("개발중입니다.");
    },
    onRemoteSupport() {
      openNewWindow({ href: "https://www.786.co.kr/index.php" });
    },
    async onHoldERP(args) {
      this.clientMonitorTitle = args.item.text;
      if(args.item.value === "OFF") {
        await this.SET_IS_HOLD_ERP(false);
        await this.fetchClientMonitorImages(this.deviceSettingInfo.clientMonitorImagePattern);
        await this.infoToast(`${args.item.text}로 전환합니다.`);
        return;
      }
      if(args.item.value === this.clientMonitorValue && this.isHoldERP) {
        this.clientMonitorValue = null;
        this.clientMonitorTitle = "OFF";
        await this.SET_IS_HOLD_ERP(false);
        await this.fetchClientMonitorImages(this.deviceSettingInfo.clientMonitorImagePattern);
      } else {
        this.clientMonitorValue = args.item.value;
        await this.SET_IS_HOLD_ERP(true);
        await this.fetchClientMonitorImages(args.item.value);
      }
      await this.infoToast(`${args.item.text}${this.isHoldERP ? '(으)로 전환' : '을 해제'}합니다.`);
    },
    toggleSideBar() {
      this.$emit('leftMenuIconClicked');
    },
    setNavigationList() {
      let navigationList = [];
      let selectedMenu = this.menuByRouterName(this.$route.name);
      if (selectedMenu) {
        // title 설정
        document.title = selectedMenu.menuName + '-' + documentBaseTitle;
        while (selectedMenu) {
          navigationList.push({
            id: selectedMenu.menuId,
            name: selectedMenu.menuName,
          });
          selectedMenu = this.menuByParentId(selectedMenu.parentId);
        }
      } else {
        // title 설정
        document.title = documentBaseTitle;
        // TODO : 대쉬보드 타이틀 설정 관련 메뉴 테이블에 데이터 추가 필요 여부 확인.
        if (this.$route.name === 'dashboard') {
          document.title = `대시보드 - ${documentBaseTitle}`;
          navigationList.push({
            id: 0,
            name: 'Dashboard',
          });
        }
      }
      this.navigationList = navigationList;
    },
    savePassword() {
      if (!this.validateFormRefs(this.changePasswordValidationRules)) {
        return;
      }
      this.updatePassword().then(() => {
        this.infoToast('변경되었습니다.');
        this.cancelPassword();
      });
    },
    cancelPassword() {
      this.$refs.passwordChangeDialog.hide();
    },
    onRightTabIconClicked() {
      this.$emit('rightTabIconClicked');
    },
    deviceSettingPopupOpen() {
      this.isDeviceSettingPopupOpened = true;
    },
    onDeviceSettingPopupClosed() {
      this.isDeviceSettingPopupOpened = false;
    },
    onPasswordChangeDialogClose() {
      this.oldPassword = '';
      this.newPassword = '';
      this.repeatPassword = '';
    },
    // API
    async fetchSystemConfigInfo() {
      const {
        value: {confCom},
      } = await getSystemConfigInfo();
      this.systemConfigInfo = confCom;
    },
    async fetchClientMonitorImages(imagePettern) {
      const data = await GolfErpAPI.fetchClientMonitorImages(imagePettern);
      const imageList = _sortBy(data.map(item => {
        return {
          imageId: item.id,
          imageUrl: item.path,
          sortNo: item.sortNo,
        };
      }), ["sortNo"]);
      const isNotHoldTypeAndOnlyOneImage = !(imageList.length <= 1);
      this.sendWebSocket('config', {
        interval: isNotHoldTypeAndOnlyOneImage && this.deviceSettingInfo.clientMonitorInterval,
        effect: isNotHoldTypeAndOnlyOneImage && this.deviceSettingInfo.clientMonitorEffect,
        imageList,
      });
    },
    async updatePassword() {
      await GolfErpAPI.updatePassword(this.oldPassword, this.newPassword);
    },
  },
};
</script>
